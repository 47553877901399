<template>
  <div>
    <div class="class_page">
      <div class="header">
        <div class="left" @click="$router.go(-1)">
          <van-icon name="arrow-left" />返回
        </div>
        <div class="info">
          <ul>
            <li v-if="$route.query.subject == 1">姓名:{{ $route.query.u_name }}</li>
            <li v-else>小组:{{ $route.query.g_name }}</li>
            <li>课程:{{ $route.query.les_name }}</li>
            <li>上课时间:{{ $route.query.poin_time }}</li>
            <li>上课教室:{{ $route.query.room_name }}</li>
            <li v-if="$route.query.ass_name">辅助老师:{{ $route.query.ass_name }}</li>
          </ul>
        </div>
        <div class="btn">
          <div @click="submit">提交</div>
        </div>
      </div>
      <div class="body" ref="body">
        <div class="table_box">
          <el-table :data="totalList.list" stripe>
            <template
              v-if="$route.query.subject == 1 && ($route.query.teach_type == 1 || $route.query.teach_type == 2)"
            >
              <el-table-column prop="main_name" label="维度" min-width="11%" align="center">
                <template slot-scope="scope">
                  <p v-html="scope.row.manor_name + '><br>' + scope.row.main_name"></p>
                </template>
              </el-table-column>
              <el-table-column prop="project_name" label="项目" min-width="16%" align="center"></el-table-column>
              <el-table-column prop="big" label="大试探" min-width="7%" align="center">
                <template slot-scope="scope">
                  <p>
                    {{
                    scope.row.big &&
                    scope.row.big.map((e) => ["N", "Y"][e.key]).join("")
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="small" label="小试探" min-width="7%" align="center">
                <template slot-scope="scope">
                  <p>
                    {{
                    scope.row.small &&
                    scope.row.small.map((e) => ["N", "Y"][e.key]).join("")
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="train" label="记录" min-width="25%" align="center">
                <template slot-scope="scope">
                  <div class="train_list">
                    <div
                      class="d_item"
                      v-for="(item1, index1) in scope.row.train"
                      :key="index1"
                      :class="{ err: item1.key == 2 }"
                    >
                      <p v-if="item1.key != 2">{{ item1.txt }}</p>
                      <van-popover
                        v-else
                        v-model="item1.showPopover"
                        trigger="click"
                        :closeOnClickAction="false"
                        :getContainer="()=>{$refs.more}"
                      >
                        <div class="pop">
                          <div
                            class="pop_item"
                            v-for="(item2, index2) in item1.list"
                            :key="index2"
                          >{{ item2.txt }}</div>
                        </div>
                        <template #reference>
                          <p>{{ item1.txt }}</p>
                        </template>
                      </van-popover>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="score" label="分数" min-width="7%" align="center"></el-table-column>
              <el-table-column prop="modality" label="辅助形式" min-width="9%" align="center">
                <template slot-scope="scope">
                  <p>
                    {{
                    [
                    "",
                    "位置辅助",
                    "视觉辅助",
                    "示范辅助",
                    "手势辅助",
                    "眼表辅助",
                    "身体辅助",
                    ][scope.row.modality]
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="is_adopt" label="结果" min-width="7%" align="center">
                <template slot-scope="scope">
                  <p>
                    {{
                    [
                    "训练中",
                    "通过"
                    ][scope.row.is_adopt]
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="edict" label="指令" min-width="7%" align="center">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="look('指令',scope.row.edict)"
                    type="text"
                    size="small"
                  >查看</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="notes" label="备注" min-width="7%" align="center">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="look('备注',scope.row.notes)"
                    type="text"
                    size="small"
                  >查看</el-button>
                </template>
              </el-table-column>
            </template>
            <template
              v-if="$route.query.subject == 1 && ($route.query.teach_type == 3 || $route.query.teach_type == 4)"
            >
              <el-table-column prop="main_name" label="维度" min-width="9%" align="center">
                <template slot-scope="scope">
                  <p v-html="scope.row.manor_name + '><br>' + scope.row.main_name"></p>
                </template>
              </el-table-column>
              <el-table-column prop="project_name" label="项目" min-width="16%" align="center"></el-table-column>
              <el-table-column prop="target" label="目标" min-width="30%" align="center"></el-table-column>
              <el-table-column prop="train" label="目标完成情况" min-width="10%" align="center">
                <template slot-scope="scope">
                  <p>
                    {{
                    [
                    "",
                    "独立完成",
                    "半辅",
                    "全辅",
                    ][scope.row.train[0].key]
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="is_train" label="项目状态" min-width="8%" align="center">
                <template slot-scope="scope">
                  <p>
                    {{
                    [
                    "未通过",
                    "通过",
                    ][scope.row.is_train]
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="notes" label="备注" min-width="8%" align="center">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="look('备注',scope.row.notes)"
                    type="text"
                    size="small"
                  >查看</el-button>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
    </div>
    <van-popup v-model="lookShow" round style="width:6rem" @close="lookShow = false">
      <div class="look_box">
        <div class="look_head">
          <p>{{lookInfo.title}}</p>
          <span @click="lookShow = false">
            <van-icon name="cross" />
          </span>
        </div>
        <div class="look_content" v-if="lookInfo.content">{{lookInfo.content}}</div>
        <div v-else>
          <van-empty description="暂无数据" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import "@/plugin/element";
export default {
  data() {
    return {
      totalList: {},
      lookShow: false,
      lookInfo: {}
    }
  },
  components: {

  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const res = await this.$http.get("bk-sunhost/get-rec", {
        params: { sunhost_id: this.$route.query.sunhost_id }
      });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.totalList = res.data
    },
    look(title, content) {
      this.lookShow = true
      this.lookInfo.title = title
      this.lookInfo.content = content
    },
    submit() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "提交记录后不可修改，是否确认提交？",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
          closeOnClickOverlay: true
        })
        .then(async () => {
          let list1 = this.totalList.list.map(e => {
            return {
              id: e.id,
              is_adopt: e.is_adopt
            }
          });
          let list2 = this.totalList.rev_list.map(e => {
            return {
              id: e.id,
              is_adopt: e.is_adopt
            }
          });
          let params = {
            uid: this.$route.query.uid,
            srecord_id: this.$route.query.id,
            sunhost_id: this.$route.query.sunhost_id,
            data: list1.length > 0 ? JSON.stringify(list1) : '', // 传空数组时只能给空字符串？
            rev_data: list2.length > 0 ? JSON.stringify(list2) : ''
          }
          const res = await this.$http.post("bk-sunhost/submit", params);
          if (res.code * 1 !== 1) {
            return this.$vantnotify(res.msg);
          }
          this.$router.replace('/index')
        })
        .catch(() => { });
    }
  },
  watch: {

  },
  computed: {

  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  width: 100%;
}
.class_page {
  background: url(~@/assets/img/bj.png) top left/100% auto no-repeat #fbf7f7;
  min-height: 100vh;
  padding-top: 0.6rem;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.2rem;
    height: 0.6rem;
    background: rgba(255, 255, 255, 0.4);
    .left {
      font-size: 0.14rem;
      color: #333;
      .van-icon {
        font-size: 0.12rem;
      }
    }
    .info {
      flex: auto;
      ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        li {
          font-size: 0.17rem;
          color: #333;
          &::before {
            content: "";
            display: inline-block;
            width: 0.06rem;
            height: 0.06rem;
            background: #ff5f4c;
            border-radius: 50%;
            margin-right: 0.1rem;
            vertical-align: middle;
          }
        }
      }
    }
    .btn {
      div {
        height: 0.34rem;
        line-height: 0.34rem;
        background: #f14947;
        border-radius: 6px;
        padding: 0 0.18rem;
        font-size: 0.14rem;
        color: #fff;
      }
    }
  }
  .body {
    padding: 0.13rem 0.16rem 0;
    position: relative;
    .table_box {
      height: calc(100vh - 0.73rem);
      background: #fff;
      padding: 0.15rem;
      border-radius: 0.08rem 0.08rem 0 0;
      letter-spacing: 0;
      /deep/ table {
        &.el-table__header tr th {
          background: #ffeeee;
          font-size: 0.14rem;
          color: #333;
          font-weight: normal;
          border-left: 0.01rem solid #e8e8e8;
          &:first-child {
            border-left: 0.01rem solid #ffeeee;
          }
          &:last-child {
            border-right: 0.01rem solid #ffeeee;
          }
        }
        td,
        th.el-table__cell {
          border-color: #e8e8e8;
        }
        &.el-table__body td {
          border-left: 0.01rem solid #e8e8e8;
          &:last-child {
            border-right: 0.01rem solid #e8e8e8;
          }
        }
      }
      .el-table {
        border-radius: 0.08rem 0.08rem 0px 0px;
      }
      /deep/ .el-table__body-wrapper {
        max-height: calc(100vh - 1.7rem);
        overflow-y: scroll;
        .cell {
          font-size: 0.13rem;
          padding: 0 0.05rem;
          .el-button {
            width: 0.5rem;
            height: 0.28rem;
            border-radius: 0.14rem;
            background: #ffebeb;
            color: #f14947;
          }
        }
      }
      .train_list {
        display: flex;
        flex-wrap: wrap;
      }
      .d_item {
        width: 0.36rem;
        line-height: 0.24rem;
        text-align: center;
        background: #f3f3f3;
        border-radius: 0.05rem;
        border: 0.01rem solid #d9d9d9;
        margin: 0.03rem;
        &.err {
          background: #ffe8e8;
          border: 1px solid #ffbcbc;
          color: #ff5f4c;
          .pop {
            display: flex;
            padding: 0.12rem 0.06rem;
            .pop_item {
              width: 0.42rem;
              height: 0.24rem;
              background: #f3f3f3;
              border: 0.01rem solid #d9d9d9;
              margin: 0 0.06rem;
              border-radius: 0.12rem;
            }
          }
        }
      }
    }
  }
}
.look_head {
  height: 0.42rem;
  line-height: 0.42rem;
  padding: 0 0.14rem;
  background: url("~@/assets/img/form_head.png") top left/100% 100% no-repeat;
  p {
    font-size: 0.16rem;
    color: #750100;
    text-align: center;
  }
  span {
    position: absolute;
    top: 0;
    right: 0.14rem;
    font-size: 0.16rem;
    color: #750100;
  }
}
.look_content {
  padding: 0.14rem;
  font-size: 0.14rem;
  color: #666;
  line-height: 24px;
  word-break: break-word;
}
/deep/ .van-dialog {
  padding-bottom: 0.2rem;
  font-size: 0.16rem;
  .van-dialog__header {
    text-align: left;
    padding: 0.2rem 0.24rem 0;
    color: #666;
  }
  .van-dialog__header::before {
    content: "!";
    display: inline-block;
    width: 0.18rem;
    height: 0.18rem;
    border-radius: 50%;
    text-align: center;
    font-size: 0.18rem;
    vertical-align: text-bottom;
    margin-right: 0.08rem;
    line-height: 0.2rem;
    padding: 0 0 0.01rem 0.01rem;
    background: #ffaa20;
    color: #fff;
    vertical-align: middle;
  }
  .van-dialog__message--has-title {
    padding-top: 0.1rem;
    padding-bottom: 0.15rem;
    font-size: 0.14rem;
    line-height: 0.3rem;
  }
  .van-dialog__footer {
    justify-content: center;
    overflow: initial;
    .van-button {
      width: 0.7rem;
      height: 0.32rem;
      flex: none;
      border-radius: 0.06rem;
      margin: 0 0.13rem;
      font-size: 0.14rem;
      box-sizing: border-box;
    }
    .van-dialog__cancel {
      border: 1px solid #b2b2b2;
      color: #707070;
    }
    .van-dialog__confirm {
      background: #fa4b52;
      color: #fff;
    }
  }
  [class*="van-hairline"]::after {
    border: none;
  }
}
</style>
